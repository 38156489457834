import React from 'react'
import { GetStarted } from "./ActionButtons";
import FeatureSVG from './FeatureSVG';

const PriceAdvanced = () => {

  return (
    <div className="bg-gray-100 flex w-full flex-col rounded-2xl border border-gray-100 shadow-md">
        <div className="w-full flex flex-col gap-4 p-8 text-center md:text-left">
            <span className="text-xl font-bold">Advanced plan</span>
            <div className="flex flex-1 grow flex-col items-center gap-2 md:hidden">
                <span className="text-center text-5xl font-bold">£59.99<span className="text-xl">/mo</span></span>
                <span className="text-center text-base">+1.6% commission</span>
            </div>
        </div>
        <div className="flex w-full px-8 pb-8">
            <div className="flex w-full flex-col md:flex-row">
            <div className="flex w-full grow flex-col md:basis-2/3 pb-8 md:pb-0 md:flex-row">
                <div className="w-full flex flex-col">
                    <div className="flex w-full pb-4">
                        <span className="text-base font-bold">Get access to:</span>
                    </div>
                    <div className="flex flex-col md:flex-row w-full">
                        <div className="flex flex-1 grow">
                            <ul>
                                <li className="flex items-center gap-2 py-2 text-left text-sm text-black">
                                    <FeatureSVG />
                                    Multiple fulfillers
                                </li>
                                <li className="flex items-center gap-2 py-2 text-left text-sm">
                                    <FeatureSVG />
                                    UK chart reporting
                                </li>
                                <li className="flex items-center gap-2 py-2 text-left text-sm text-black">
                                    <FeatureSVG />
                                    Fan clubs
                                </li>
                            </ul>
                        </div>
                        <div className="flex flex-1 grow items-start">
                            <ul>
                                <li className="flex items-center gap-2 py-2 text-left text-sm text-black">
                                    <FeatureSVG />
                                    YouTube Merch shelf
                                </li>
                                <li className="flex items-center gap-2 py-2 text-left text-sm text-black">
                                    <FeatureSVG />
                                    Advanced SEO
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex basis-1/3 grow flex-col items-center gap-3">
                <span className="hidden md:block text-center text-5xl font-bold">£59.99<span className="text-xl">/mo</span></span>
                <span className="hidden md:block text-center text-base">+1.6% commission</span>
                <div className="py-2 md:px-4 text-base w-full flex flex-col items-center gap-2">
                    <GetStarted className="py-2 px-4 text-base w-full hover:bg-mg-yellow hover:text-mg-purple" />
                    <a 
                        href="#table" 
                        title="Click to view all features"
                        className="text-mg-purple text-sm font-semibold"
                    >
                        View all features
                    </a>
                </div>
            </div>
            </div>
        </div>
        </div>
  )
}

export default PriceAdvanced