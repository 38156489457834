import React from 'react'

export interface continentProps {
    continent: string,
    countries: string[],
}

const ContinentCard = ( {continent, countries }: continentProps ) => {

    return (
        <div className="w-full flex flex-col gap-4 p-6 rounded-2xl border border-gray-100 shadow-sm">
            <div className="flex flex-col gap-2">
                <h2 className="!my-0">{continent}</h2>
                <hr className="border border-gray-100 my-0" />
            </div>
            <ul className="flex flex-col gap-1 list-disc mb-0">
                {countries && countries.map(country => (
                    <li className="list-inside text-left md:text-sm">{country}</li>
                ))}
            </ul>
        </div>
    )

}

export default ContinentCard